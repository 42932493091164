import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3abaa812 = () => interopDefault(import('../pages/401/index.vue' /* webpackChunkName: "pages/401/index" */))
const _5d91af7c = () => interopDefault(import('../pages/demo/index.vue' /* webpackChunkName: "pages/demo/index" */))
const _06426754 = () => interopDefault(import('../pages/forbidden/index.vue' /* webpackChunkName: "pages/forbidden/index" */))
const _43e8ad28 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0ec34e8b = () => interopDefault(import('../pages/sites/_sid/index.vue' /* webpackChunkName: "pages/sites/_sid/index" */))
const _9ad93e72 = () => interopDefault(import('../pages/preview/_sid/_lang/_pageid/index.vue' /* webpackChunkName: "pages/preview/_sid/_lang/_pageid/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/401",
    component: _3abaa812,
    name: "401"
  }, {
    path: "/demo",
    component: _5d91af7c,
    name: "demo"
  }, {
    path: "/forbidden",
    component: _06426754,
    name: "forbidden"
  }, {
    path: "/",
    component: _43e8ad28,
    name: "index"
  }, {
    path: "/sites/:sid",
    component: _0ec34e8b,
    name: "sites-sid"
  }, {
    path: "/preview/:sid?/:lang?/:pageid",
    component: _9ad93e72,
    name: "preview-sid-lang-pageid"
  }, {
    path: "/:lang/",
    component: _43e8ad28,
    props: true,
    name: "SiteLangIndexPage"
  }, {
    path: "/:lang/:pageid",
    component: _43e8ad28,
    props: true,
    name: "SiteLangPagePage"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
